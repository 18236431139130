import React, { useContext, useEffect, useMemo, useState } from "react";
import AdobePdfViewer from "../../module/AdobePdfViewer";
import { useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContextProvider";
import log from "../../util/Logger";
import { useUtilManager } from "../../util/UtilManager";
import { CandidateContext } from "../../context/CandidateContextProvider";
import GenericButton from "../../module/GenericButton";
import { BUTTON_LABEL, POP_UP, VIEW, WARNING } from "../../util/Constants";
import { toast } from "react-toastify";

const getCandidateApi =
  "https://0wjwdob7zc.execute-api.ap-south-1.amazonaws.com/Prod/get-candidate";
export default function CandidateDetails() {
  const location = useLocation();
  const currentPath = location.pathname;
  const {
    setIsLoading,
    uid,
    setPopup,
    candidateDetailsViewRefresher,
    setCandidateDetailsViewRefresher,
    isLoggedIn,
  } = useContext(GlobalContext);
  const { setSelectedCandidates, updateCandidate } =
    useContext(CandidateContext);
  const [candidate, setCandidate] = useState(null);
  let { id } = useParams();

  const {
    capitalizeFirstChar,
    formatTs,
    handleSubmitToJobsClick,
    handleSimilarCandidatesClick,
    handleFindJobsClick,
    handleSubmissionOfCandidateClick,
    sendMessageToTargetUid,
  } = useUtilManager();

  const isMyCandidate = useMemo(() => {
    return candidate !== null && candidate.uid === uid;
  }, [candidate, uid]);

  const convertResumeData = (resumeContentBase64) => {
    const decodedContent = atob(resumeContentBase64);
    const byteArray = Uint8Array.from(decodedContent, (c) => c.charCodeAt(0));
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  };

  const fetchCandidateDetails = async (candId) => {
    setIsLoading(true);
    const payload = {
      candidate_id: candId,
    };
    const postParams = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    try {
      const response = await fetch(getCandidateApi, postParams);
      // log.debug('Response received:', response);
      if (response.ok) {
        const data = await response.json();
        const resume_file_data = convertResumeData(data.resume_file_data);
        setCandidate({ ...data, resume_file_data });
        setSelectedCandidates([data]);
      } else {
        log.debug("Response not ok, processing error...");
        const errorResponse = await response.json();
        log.debug("Error fetching jobs:", errorResponse.message);
        throw new Error(errorResponse.message);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const isActive = useMemo(() => {
    return candidate !== null && candidate.status === "active";
  }, [candidate]);

  const handleUpdateCandidate = () => {
    setPopup(POP_UP.UPDATE_CANDIDATE_POPUP);
  };

  const handleRenewDate = async () => {
    const updatedCandidate = {
      id: candidate.id,
    };
    await updateCandidate(updatedCandidate);
    setCandidateDetailsViewRefresher((candidateDetailsViewRefresher + 1) % 2);
  };

  const handleDeactivateCandidate = async () => {
    const updatedCandidate = {
      id: candidate.id,
      status: "inactive",
      uid_status: candidate.uid + "#inactive",
    };
    await updateCandidate(updatedCandidate);
    setCandidateDetailsViewRefresher((candidateDetailsViewRefresher + 1) % 2);
  };

  const handleActivateCandidate = async () => {
    const updatedCandidate = {
      id: candidate.id,
      status: "active",
      uid_status: candidate.uid + "#active",
    };
    await updateCandidate(updatedCandidate);
    setCandidateDetailsViewRefresher((candidateDetailsViewRefresher + 1) % 2);
  };

  // const handleDeleteCandidate = async () => {
  //     const updatedCandidate = {
  //         id: candidate.id,
  //         status: 'deleted',
  //         uid_status: candidate.uid + '#deleted'
  //     };
  //     await updateCandidate(updatedCandidate);
  //     setCandidateDetailsViewRefresher((candidateDetailsViewRefresher + 1) % 2);
  // }

  const sendMessageClick = async () => {
    if (!isLoggedIn) {
      toast.warn(WARNING.LOGIN_TO_CHAT);
      return;
    }
    sendMessageToTargetUid(candidate.uid);
  };

  useEffect(() => {
    const currentView = currentPath.split("/")[1];
    if (id === undefined || currentView !== VIEW.CANDIDATE_DETAILS) {
      return;
    }
    fetchCandidateDetails(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath, candidateDetailsViewRefresher]);

  return (
    <div className="font-roboto text-[12px] mt-10 mb-10">
      {candidate && (
        <div>
          <div className="mb-2 text-right font-roboto text-[10px] font-semibold flex flex-row justify-between text-[#e8ddfe]">
            <div className="flex flex-row justify-start">
              {isMyCandidate && (
                <>
                  <GenericButton
                    enable={true}
                    callback={handleSubmitToJobsClick}
                    text={"Submit to Jobs"}
                    mr={"mr-2"}
                    ml={"ml-0"}
                  />
                  {/* <GenericButton enable={true && isActive} callback={handleSubmissionToCandidateClick} text={"Submissions To this Candidate"} mr={'mr-2'} ml={'ml-0'} /> */}
                  <GenericButton
                    enable={true && isActive}
                    callback={handleSubmissionOfCandidateClick}
                    text={"My Submissions"}
                    mr={"mr-8"}
                    ml={"ml-0"}
                  />
                </>
              )}
              {!isMyCandidate && (
                <>
                  {/* <GenericButton enable={true} callback={handleSubmitYourJobsClick} text={"Submit Your Jobs"} mr={'mr-2'} ml={'ml-0'} /> */}
                  <GenericButton
                    enable={isLoggedIn}
                    callback={sendMessageClick}
                    text={BUTTON_LABEL.CHAT_WITH_VENDOR}
                    mr={"mr-2"}
                    ml={"ml-0"}
                    animation={true}
                  />
                  <GenericButton
                    enable={true}
                    callback={handleSimilarCandidatesClick}
                    text={"Find Similar Candidates"}
                    mr={"mr-2"}
                    ml={"ml-0"}
                  />
                  <GenericButton
                    enable={true}
                    callback={handleFindJobsClick}
                    text={"Find Jobs"}
                    mr={"mr-2"}
                    ml={"ml-0"}
                  />
                </>
              )}
            </div>
            <div className="flex flex-row justify-end">
              {isMyCandidate && (
                <>
                  <GenericButton
                    enable={true}
                    callback={handleRenewDate}
                    text={"Renew Date"}
                    mr={"mr-2"}
                    ml={"ml-0"}
                  />
                  {isActive && (
                    <GenericButton
                      enable={true}
                      callback={handleDeactivateCandidate}
                      text={"Deactivate"}
                      mr={"mr-2"}
                      ml={"ml-0"}
                    />
                  )}
                  {!isActive && (
                    <GenericButton
                      enable={true}
                      callback={handleActivateCandidate}
                      text={"Activate"}
                      mr={"mr-2"}
                      ml={"ml-0"}
                    />
                  )}
                  {/* <GenericButton enable={true} callback={handleDeleteCandidate} text={"Delete"} mr={'mr-2'} ml={'ml-0'} /> */}
                  <GenericButton
                    enable={true}
                    callback={handleUpdateCandidate}
                    text={"Update"}
                    mr={"mr-0"}
                    ml={"ml-0"}
                  />
                </>
              )}
            </div>
          </div>
          <table
            className="text-left break-words overflow-hidden bg-[#eaeaea] text-gray-800"
            cellPadding="2"
            cellSpacing="0"
            style={{ tableLayout: "fixed", width: "750px" }}
          >
            <tbody>
              <tr className="border-b-4 border-white">
                <td className="p-1 font-semibold w-1/6"> Name: </td>
                <td className="p-1 w-5/6"> {candidate.name} </td>
              </tr>
              <tr className="border-b-4 border-white">
                <td className="p-1 font-semibold"> Top Skills: </td>
                <td className="p-1"> {candidate.top_skills} </td>
              </tr>
              <tr className="border-b-4 border-white">
                <td className="p-1 font-semibold">Location: </td>
                <td className="p-1"> {candidate.loc} </td>
              </tr>
              <tr className="border-b-4 border-white">
                <td className="p-1 font-semibold">Open to Relocate: </td>
                <td className="p-1">
                  {" "}
                  {candidate.open_to_relocate
                    ? candidate.open_to_relocate
                    : "Not Available"}{" "}
                </td>
              </tr>
              <tr className="border-b-4 border-white">
                <td className="p-1 font-semibold">Experience: </td>
                <td className="p-1"> {candidate.exp} Years </td>
              </tr>
              <tr className="border-b-4 border-white">
                <td className="p-1 font-semibold">Visa: </td>
                <td className="p-1"> {candidate.visa} </td>
              </tr>
              <tr className="border-b-4 border-white">
                <td className="p-1 font-semibold">Work Preference: </td>
                <td className="p-1"> {candidate.work_pref} </td>
              </tr>
              <tr className="border-b-4 border-white">
                <td className="p-1 font-semibold">Status: </td>
                <td className="p-1">
                  {" "}
                  {capitalizeFirstChar(candidate.status)}{" "}
                </td>
              </tr>
              <tr className="border-b-4 border-white">
                <td className="p-1 font-semibold">Posted By: </td>
                <td className="p-1"> {candidate.uid} </td>
              </tr>
              <tr className="border-b-4 border-white">
                <td className="p-1 font-semibold">Posted On: </td>
                <td className="p-1"> {formatTs(candidate.create_ts)} </td>
              </tr>
              <tr className="border-b-4 border-white">
                <td className="p-1 font-semibold">Updated On: </td>
                <td className="p-1"> {formatTs(candidate.ts)} </td>
              </tr>
              <tr className="border-b-4 border-white">
                <td className="p-1" colspan="2">
                  {" "}
                  <span className="font-semibold">Resume:</span> <br /> <br />
                  <AdobePdfViewer
                    resume_file_data={candidate.resume_file_data}
                    resume_file_name={candidate.resume_file_name}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
