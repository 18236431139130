import React, { useContext, useEffect, useMemo } from "react";
import { GlobalContext } from "../context/GlobalContextProvider";
import { BUTTON_LABEL, VIEW, WARNING } from "../util/Constants";
import { useUtilManager } from "../util/UtilManager";
import { JobContext } from "../context/JobContextProvider";
import { CandidateContext } from "../context/CandidateContextProvider";
import JobList from "../module/JobList";
import CandidateList from "../module/CandidateList";
import log from "../util/Logger";
import { toast } from "react-toastify";
import GenericButton from "../module/GenericButton";
import { useParams } from "react-router-dom";

const jobSubmissionApi = `https://zl8byzl7c1.execute-api.ap-south-1.amazonaws.com/Prod/submit-jobs`;
const candidateSubmissionApi = `https://zl8byzl7c1.execute-api.ap-south-1.amazonaws.com/Prod/submit-candidates`;
const getSubmissionCountOfSourceIdApi =
  "https://zl8byzl7c1.execute-api.ap-south-1.amazonaws.com/Stage/get-submissions-count-of-src-id";
const getRecommendationsCountOfSourceIdApi =
  "https://p757tbfjc9.execute-api.ap-south-1.amazonaws.com/Prod/get-recommendations-count-of-src-id";
const getJobsByIdsApi =
  "https://m9l0gpbw58.execute-api.ap-south-1.amazonaws.com/Prod/get-jobs-by-ids";
const globalSubmissionLimit = 5;
const globalRecommendationsLimit = 10;
export default function SubmissionView() {
  let { id } = useParams();
  const { uid, currentView, setIsLoading, isLoggedIn } =
    useContext(GlobalContext);
  const {
    doNothing,
    isQA,
    getAiRecommendation,
    sendMessageToTargetUid,
    publishMetric
  } = useUtilManager();

  const {
    isAnyCandidateSelected,
    selectedCandidates,
    candidateList,
    handleCheckBoxChangeOnCandidateList,
    handleCandidateClick,
    handleFindCandidates,
    isCandidateSelected,
    setMultipleSelectionAllowedForCandidates,
    getSubmissionsToJob,
    getSubmissionsOfJob,
  } = useContext(CandidateContext);

  const {
    selectedJobs,
    isAnyJobSelected,
    jobList,
    handleCheckBoxChangeOnJobList,
    handleJobClick,
    handleFindJobs,
    isJobSelected,
    setMultipleSelectionAllowedForJobs,
    getSubmissionsToCandidate,
    getSubmissionsOfCandidate,
    setSelectedJobs,
  } = useContext(JobContext);

  const topMessage = () => {
    switch (currentView) {
      case VIEW.SUBMIT_YOUR_CANDIDATES:
        return "Submit your candidates to the selected job";
      case VIEW.SUBMIT_YOUR_JOBS:
        return "Submit your jobs to the selected candidate";
      case VIEW.SUBMIT_TO_CANDIDATES:
        return "Submit your job to the top matched candidates";
      case VIEW.SUBMIT_TO_JOBS:
        return "Submit your candidate to the top matched jobs";
      case VIEW.SUBMISSIONS_TO_JOB:
        return "Submissions to your job";
      case VIEW.SUBMISSIONS_OF_JOB:
        return "Submissions of your job";
      case VIEW.SUBMISSIONS_TO_CANDIDATE:
        return "Submissions to your candidate";
      case VIEW.SUBMISSIONS_OF_CANDIDATE:
        return "Submissions of your candidate";
      default:
        return null;
    }
  };

  const getTopListMessage = () => {
    switch (currentView) {
      case VIEW.SUBMIT_YOUR_CANDIDATES:
        return "Selected Job";
      case VIEW.SUBMIT_YOUR_JOBS:
        return "Selected Candidate";
      case VIEW.SUBMISSIONS_TO_CANDIDATE:
      case VIEW.SUBMISSIONS_OF_CANDIDATE:
      case VIEW.SUBMIT_TO_JOBS:
        return "Your Candidate";
      case VIEW.SUBMISSIONS_TO_JOB:
      case VIEW.SUBMISSIONS_OF_JOB:
      case VIEW.SUBMIT_TO_CANDIDATES:
        return "Your Job";
      default:
        return null;
    }
  };

  const getBottomListFirstMessage = () => {
    switch (currentView) {
      case VIEW.SUBMIT_YOUR_CANDIDATES:
        return "Your Candidates";
      case VIEW.SUBMIT_YOUR_JOBS:
        return "Your Jobs";
      case VIEW.SUBMIT_TO_CANDIDATES:
        return "Top Matched Candidates";
      case VIEW.SUBMIT_TO_JOBS:
        return "Top Matched Jobs";
      case VIEW.SUBMISSIONS_TO_JOB:
        return "Submitted Candidates";
      case VIEW.SUBMISSIONS_OF_JOB:
        return "Submissions";
      case VIEW.SUBMISSIONS_TO_CANDIDATE:
        return "Submitted Jobs";
      case VIEW.SUBMISSIONS_OF_CANDIDATE:
        return "Submissions";
      default:
        return null;
    }
  };

  const getBottomListSecondMessage = () => {
    switch (currentView) {
      case VIEW.SUBMIT_YOUR_CANDIDATES:
        return "(Please select one or more candidate for submission and AI recommendation...)";
      case VIEW.SUBMIT_YOUR_JOBS:
        return "(Please select one or more job for submission and AI recommendation...)";
      case VIEW.SUBMIT_TO_CANDIDATES:
        return "(Please select one or more candidate for submission and AI recommendation...)";
      case VIEW.SUBMIT_TO_JOBS:
        return "(Please select one or more job for submission and AI recommendation...)";
      case VIEW.SUBMISSIONS_TO_JOB:
        return "(Following candidates have been submitted to your job...)";
      case VIEW.SUBMISSIONS_OF_JOB:
        return "(Your job has been submitted to following candidates...)";
      case VIEW.SUBMISSIONS_OF_CANDIDATE:
        return "(Your candidate has been submitted to following jobs...)";
      case VIEW.SUBMISSIONS_TO_CANDIDATE:
        return "(Following jobs have been submitted to your candidate...)";
      default:
        return null;
    }
  };

  const renderTopList = () => {
    switch (currentView) {
      case VIEW.SUBMISSIONS_TO_JOB:
      case VIEW.SUBMISSIONS_OF_JOB:
      case VIEW.SUBMIT_TO_CANDIDATES:
      case VIEW.SUBMIT_YOUR_CANDIDATES:
        return (
          <JobList
            jobList={selectedJobs}
            tableWidth={"1205px"}
            isJobSelected={isJobSelected}
            handleJobClick={handleJobClick}
            handleCheckBoxChange={doNothing}
            displayScore={false}
            displayRecommendation={false}
          />
        );
      case VIEW.SUBMISSIONS_OF_CANDIDATE:
      case VIEW.SUBMISSIONS_TO_CANDIDATE:
      case VIEW.SUBMIT_TO_JOBS:
      case VIEW.SUBMIT_YOUR_JOBS:
        return (
          <CandidateList
            candidateList={selectedCandidates}
            isCandidateSelected={isCandidateSelected}
            isCandidateSearchView={false}
            handleCandidateClick={handleCandidateClick}
            handleCheckBoxChange={doNothing}
            displayScore={false}
            displayRecommendation={false}
          />
        );
      default:
        return null;
    }
  };

  const renderBottomList = () => {
    switch (currentView) {
      case VIEW.SUBMISSIONS_TO_JOB:
      case VIEW.SUBMISSIONS_OF_JOB:
      case VIEW.SUBMIT_TO_CANDIDATES:
      case VIEW.SUBMIT_YOUR_CANDIDATES:
        return (
          <CandidateList
            candidateList={candidateList}
            isCandidateSelected={isCandidateSelected}
            isCandidateSearchView={true}
            handleCandidateClick={handleCandidateClick}
            handleCheckBoxChange={handleCheckBoxChangeOnCandidateList}
            displayScore={true}
            displayRecommendation={true}
          />
        );
      case VIEW.SUBMISSIONS_OF_CANDIDATE:
      case VIEW.SUBMISSIONS_TO_CANDIDATE:
      case VIEW.SUBMIT_TO_JOBS:
      case VIEW.SUBMIT_YOUR_JOBS:
        return (
          <JobList
            jobList={jobList}
            tableWidth={"1180px"}
            isJobSelected={isJobSelected}
            handleJobClick={handleJobClick}
            handleCheckBoxChange={handleCheckBoxChangeOnJobList}
            displayScore={true}
            displayRecommendation={true}
          />
        );
      default:
        return null;
    }
  };

  const getSubmissionItems = (jobs, candidates, search_type) => {
    let items = [];
    for (let i = 0; i < jobs.length; i++) {
      for (let j = 0; j < candidates.length; j++) {
        let score =
          search_type === "jobs" ? jobs[i].score : candidates[j].score;
        items.push({
          job_id: jobs[i].id,
          cand_id: candidates[j].id,
          match_score: score,
        });
      }
    }
    return items;
  };

  const getSourceIdSubmissionCount = async (srcId) => {
    setIsLoading(true);
    const payload = {
      src_id: srcId,
    };
    const postParams = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    try {
      const response = await fetch(getSubmissionCountOfSourceIdApi, postParams);
      if (response.ok) {
        const data = await response.json();
        return data.count;
      } else {
        log.debug("Response not ok, processing error...");
        const errorResponse = await response.json();
        log.debug(
          "Error fetching user submission count",
          errorResponse.message
        );
        throw new Error(errorResponse.message);
      }
    } catch (error) {
      log.error(error.message);
      return 0;
    }
  };

  const getSourceIdRecommendationCount = async (srcId) => {
    setIsLoading(true);
    const payload = {
      src_id: srcId,
    };
    const postParams = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    try {
      const response = await fetch(
        getRecommendationsCountOfSourceIdApi,
        postParams
      );
      if (response.ok) {
        const data = await response.json();
        return data.count;
      } else {
        log.debug("Response not ok, processing error...");
        const errorResponse = await response.json();
        log.debug(
          "Error fetching user submission count",
          errorResponse.message
        );
        throw new Error(errorResponse.message);
      }
    } catch (error) {
      log.error(error.message);
      return 0;
    }
  };

  const validateRecommendationsLimit = async (srcId, srcType) => {
    let recommendationsCount = await getSourceIdRecommendationCount(srcId);
    let recommendationsLimit = isQA ? 100 : globalRecommendationsLimit;
    if (recommendationsCount >= recommendationsLimit) {
      setIsLoading(false);
      toast.warn(
        `This ${srcType} reached the daily limit of ${recommendationsLimit} recommendations. Please try again later!`
      );
      return false;
    }
    return true;
  };

  const getAiRecommendationClick = async () => {
    if (!isAnyJobSelected) {
      toast.warn("Please select a job to get AI recommendation!");
      return;
    }
    if (!isAnyCandidateSelected) {
      toast.warn("Please select a candidate to get AI recommendation!");
      return;
    }
    switch (currentView) {
      case VIEW.SUBMISSIONS_TO_JOB:
      case VIEW.SUBMISSIONS_OF_JOB:
      case VIEW.SUBMIT_TO_CANDIDATES: {
        if (!(await validateRecommendationsLimit(selectedJobs[0].id, "job"))) {
          return;
        }
        let recommendations = await getAiRecommendation(
          selectedJobs,
          selectedCandidates,
          "job",
          "candidate"
        );
        for (let i = 0; i < recommendations.length; i++) {
          for (let j = 0; j < selectedCandidates.length; j++) {
            if (recommendations[i].cand_id === selectedCandidates[j].id) {
              selectedCandidates[j].recommendation =
                recommendations[i].recommendation;
              selectedCandidates[j].reasoning = recommendations[i].reasoning;
            }
          }
        }
        break;
      }
      case VIEW.SUBMIT_YOUR_CANDIDATES: {
        let recommendations = await getAiRecommendation(
          selectedJobs,
          selectedCandidates,
          "candidate",
          "job"
        );
        for (let i = 0; i < recommendations.length; i++) {
          for (let j = 0; j < selectedCandidates.length; j++) {
            if (recommendations[i].cand_id === selectedCandidates[j].id) {
              selectedCandidates[j].recommendation =
                recommendations[i].recommendation;
              selectedCandidates[j].reasoning = recommendations[i].reasoning;
            }
          }
        }
        break;
      }
      case VIEW.SUBMISSIONS_OF_CANDIDATE:
      case VIEW.SUBMISSIONS_TO_CANDIDATE:
      case VIEW.SUBMIT_TO_JOBS: {
        if (
          !(await validateRecommendationsLimit(
            selectedCandidates[0].id,
            "candidate"
          ))
        ) {
          return;
        }
        let recommendations = await getAiRecommendation(
          selectedJobs,
          selectedCandidates,
          "candidate",
          "job"
        );
        for (let i = 0; i < recommendations.length; i++) {
          for (let j = 0; j < selectedJobs.length; j++) {
            if (recommendations[i].job_id === selectedJobs[j].id) {
              selectedJobs[j].recommendation =
                recommendations[i].recommendation;
              selectedJobs[j].reasoning = recommendations[i].reasoning;
            }
          }
        }
        break;
      }
      case VIEW.SUBMIT_YOUR_JOBS: {
        let recommendations = await getAiRecommendation(
          selectedJobs,
          selectedCandidates,
          "job",
          "candidate"
        );
        for (let i = 0; i < recommendations.length; i++) {
          for (let j = 0; j < selectedJobs.length; j++) {
            if (recommendations[i].job_id === selectedJobs[j].id) {
              selectedJobs[j].recommendation =
                recommendations[i].recommendation;
              selectedJobs[j].reasoning = recommendations[i].reasoning;
            }
          }
        }
        break;
      }
      default:
        return null;
    }
  };

  const validateSubmissionLimit = async (jobs, candidates, submission_type) => {
    let srcId = null;
    if (submission_type === "job_submission") {
      srcId = jobs[0].id;
    } else {
      srcId = candidates[0].id;
    }
    let submissionCount = await getSourceIdSubmissionCount(srcId);
    let submissionLimit = isQA ? 100 : globalSubmissionLimit;
    if (submissionCount >= submissionLimit) {
      setIsLoading(false);
      toast.warn(
        `This ${
          submission_type === "job_submission" ? "job" : "candidate"
        } reached the daily limit of ${submissionLimit} submissions. Keeping submission limit low for couple of weeks to avoid spams. We will increase the limit in few weeks.Please try again tomorrow!`
      );
      return false;
    }
    return true;
  };

  const handleSubmission = async (
    jobs,
    candidates,
    submission_type,
    search_type
  ) => {
    if (candidates.length === 0) {
      toast.warn("Please select at least one candidate!");
      return;
    }
    if (jobs.length === 0) {
      toast.warn("Please select at least one job");
      return;
    }
    if (!(await validateSubmissionLimit(jobs, candidates, submission_type))) {
      return;
    }
    let apiUrl =
      submission_type === "job_submission"
        ? jobSubmissionApi
        : candidateSubmissionApi;
    let items = getSubmissionItems(jobs, candidates, search_type);
    const payload = {
      items: items,
    };
    const post_params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    fetch(apiUrl, post_params)
      .then((response) => {
        if (!response.ok) {
          return response.text().then((errorText) => {
            throw new Error(errorText);
          });
        } else {
          toast.success("Submission sucessfull!");
        }
        log.debug(response.text);
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const displaySubmitButton = useMemo(() => {
    return (
      currentView === VIEW.SUBMIT_YOUR_CANDIDATES ||
      currentView === VIEW.SUBMIT_TO_JOBS
    );
  }, [currentView]);

  const displaySendMessageButton = useMemo(() => {
    return (
      currentView === VIEW.SUBMISSIONS_TO_JOB ||
      currentView === VIEW.SUBMISSIONS_OF_JOB ||
      currentView === VIEW.SUBMIT_TO_CANDIDATES ||
      currentView === VIEW.SUBMISSIONS_TO_CANDIDATE ||
      currentView === VIEW.SUBMISSIONS_OF_CANDIDATE ||
      currentView === VIEW.SUBMIT_TO_JOBS
    );
  }, [currentView]);

  const sendMesssageButtonText = useMemo(() => {
      if (currentView === VIEW.SUBMISSIONS_TO_JOB ||
      currentView === VIEW.SUBMISSIONS_OF_JOB ||
      currentView === VIEW.SUBMIT_TO_CANDIDATES) {
        return BUTTON_LABEL.CHAT_WITH_VENDOR;
      }
      if (currentView === VIEW.SUBMISSIONS_TO_CANDIDATE ||
      currentView === VIEW.SUBMISSIONS_OF_CANDIDATE ||
      currentView === VIEW.SUBMIT_TO_JOBS) {
        return BUTTON_LABEL.CHAT_WITH_RECRUITER;
      }
  }, [currentView]);

  const sendMessageClick = async () => {
    publishMetric(`send_message_${currentView}_${isLoggedIn}`, 1, "Count");
    if (!isAnyJobSelected) {
      toast.warn(WARNING.SELECT_A_JOB_TO_CHAT);
      return;
    }
    if (!isAnyCandidateSelected) {
      toast.warn(WARNING.SELECT_A_CANDIDATE_TO_CHAT);
      return;
    }
    if (!isLoggedIn) {
      toast.warn(WARNING.LOGIN_TO_CHAT);
      return;
    }
    if(sendMesssageButtonText === BUTTON_LABEL.CHAT_WITH_VENDOR && selectedJobs[0].uid !== uid) {
        toast.warn(WARNING.NOT_ALLOWED_TO_CHAT_OTHER_JOB);
        return;
    }
    if(sendMesssageButtonText === BUTTON_LABEL.CHAT_WITH_RECRUITER && selectedCandidates[0].uid !== uid) {
      toast.warn(WARNING.NOT_ALLOWED_TO_CHAT_OTHER_CANDIDATE);
      return;
  }
    let targetUid = null;
    switch (currentView) {
      case VIEW.SUBMISSIONS_TO_JOB:
      case VIEW.SUBMISSIONS_OF_JOB:
      case VIEW.SUBMIT_TO_CANDIDATES:
        if (selectedCandidates.length > 1) {
          toast.warn(
            "Please select only one candidate to send message to vendor!"
          );
          return;
        }
        targetUid = selectedCandidates[0].uid;
        break;
      case VIEW.SUBMISSIONS_TO_CANDIDATE:
      case VIEW.SUBMISSIONS_OF_CANDIDATE:
      case VIEW.SUBMIT_TO_JOBS:
        if (selectedJobs.length > 1) {
          toast.warn(
            "Please select only one job to send message to recruiter!"
          );
          return;
        }
        targetUid = selectedJobs[0].uid;
        break;
      default:
        break;
    }
    sendMessageToTargetUid(targetUid);
  };

  const setSelectJobsForJobId = async (jobId) => {
    setIsLoading(true);
    const payload = {
      job_ids: [jobId],
    };
    const postParams = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    try {
      const response = await fetch(getJobsByIdsApi, postParams);
      if (response.ok) {
        const data = await response.json();
        setSelectedJobs(data);
      } else {
        log.debug("Response not ok, processing error...");
        const errorResponse = await response.json();
        log.debug("Error fetching jobs:", errorResponse.message);
        throw new Error(errorResponse.message);
      }
    } catch (error) {
      toast.error("Error fetching jobs!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewChange = async () => {
    switch (currentView) {
      case VIEW.SUBMIT_YOUR_CANDIDATES:
        handleFindCandidates(
          selectedJobs[0],
          "user",
          uid,
          VIEW.SUBMIT_YOUR_CANDIDATES
        );
        setMultipleSelectionAllowedForCandidates(true);
        break;
      case VIEW.SUBMIT_YOUR_JOBS:
        handleFindJobs(
          selectedCandidates[0],
          "user",
          uid,
          VIEW.SUBMIT_YOUR_JOBS
        );
        setMultipleSelectionAllowedForJobs(true);
        break;
      case VIEW.SUBMIT_TO_CANDIDATES:
        handleFindCandidates(
          selectedJobs[0],
          "all",
          uid,
          VIEW.SUBMIT_TO_CANDIDATES
        );
        setMultipleSelectionAllowedForCandidates(true);
        break;
      case VIEW.SUBMIT_TO_JOBS:
        handleFindJobs(selectedCandidates[0], "all", uid, VIEW.SUBMIT_TO_JOBS);
        setMultipleSelectionAllowedForJobs(true);
        break;
      case VIEW.SUBMISSIONS_TO_JOB:
        if (
          (id && selectedJobs.length !== 0 && selectedJobs[0].id !== id) ||
          (id && selectedJobs.length === 0)
        ) {
          await setSelectJobsForJobId(id);
          await getSubmissionsToJob(id);
        } else {
          getSubmissionsToJob(selectedJobs[0].id);
        }
        setMultipleSelectionAllowedForCandidates(true);
        break;
      case VIEW.SUBMISSIONS_OF_JOB:
        getSubmissionsOfJob(selectedJobs[0]);
        setMultipleSelectionAllowedForCandidates(true);
        break;
      case VIEW.SUBMISSIONS_OF_CANDIDATE:
        getSubmissionsOfCandidate(selectedCandidates[0]);
        setMultipleSelectionAllowedForJobs(true);
        break;
      case VIEW.SUBMISSIONS_TO_CANDIDATE:
        getSubmissionsToCandidate(selectedCandidates[0]);
        setMultipleSelectionAllowedForJobs(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleViewChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView, id, uid]);

  return (
    <>
      <p className="mt-3 font-roboto font-semibold bg-gray-300 px-10 py-1 rounded-sm">
        {topMessage()}
      </p>
      <div className="mr-3">
        <div className="mt-1 pt-1 flex flex-row justify-start font-roboto">
          <p className="text-base font-semibold">{getTopListMessage()}</p>
        </div>
        {renderTopList()}
        <hr />
      </div>
      <div>
        <div className="flex flex-row justify-between mt-4 font-roboto">
          <div className="flex flex-row justify-start">
            <p className="text-base font-semibold pt-1">
              {getBottomListFirstMessage()}
            </p>
            <p className="text-[13px] ml-1 pt-[7px]">
              {getBottomListSecondMessage()}
            </p>
          </div>
          <div className="pt-2 mb-1 text-[11px] font-semibold flex flex-row justify-end">
            {displaySendMessageButton && (
              <GenericButton
                enable={
                  isAnyJobSelected && isAnyCandidateSelected && isLoggedIn
                }
                callback={sendMessageClick}
                text={sendMesssageButtonText}
                mr={"mr-4"}
                ml={"ml-0"}
                animation={true}
              />
            )}
            <GenericButton
              enable={isAnyJobSelected && isAnyCandidateSelected}
              callback={getAiRecommendationClick}
              text={"Get AI Recommendation"}
              mr={"mr-4"}
              ml={"ml-0"}
            />
          </div>
        </div>
        <div className="h-[440px] overflow-y-scroll">{renderBottomList()}</div>
        <hr />
      </div>
      {displaySubmitButton && (
        <div className="mt-3 mb-2 font-roboto font-semibold">
          <button
            onClick={(event) => {
              event.stopPropagation();
              switch (currentView) {
                case VIEW.SUBMIT_YOUR_CANDIDATES:
                  handleSubmission(
                    selectedJobs,
                    selectedCandidates,
                    "candidate_submission",
                    "candidates"
                  );
                  break;
                case VIEW.SUBMIT_TO_CANDIDATES:
                  handleSubmission(
                    selectedJobs,
                    selectedCandidates,
                    "job_submission",
                    "candidates"
                  );
                  break;
                case VIEW.SUBMIT_YOUR_JOBS:
                  handleSubmission(
                    selectedJobs,
                    selectedCandidates,
                    "job_submission",
                    "jobs"
                  );
                  break;
                case VIEW.SUBMIT_TO_JOBS:
                  handleSubmission(
                    selectedJobs,
                    selectedCandidates,
                    "candidate_submission",
                    "jobs"
                  );
                  break;
                default:
                  break;
              }
            }}
            className={`border-[#24386c] border bg-[#24386c] py-1 px-2 rounded-sm text-[#e8ddfe] font-semibold text-sm ${
              isAnyCandidateSelected && isAnyJobSelected
                ? "bg-[#24386c] text-[#e8ddfe] hover:bg-[#dc244c]"
                : "bg-[#e8ddfe] text-gray-800"
            }`}
          >
            Submit
          </button>
        </div>
      )}
    </>
  );
}
